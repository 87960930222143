.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  max-width: 40rem;
  background-color: #fff;
  padding: 3rem;
  border-radius: 1rem;
}

.login-col-space {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.login-col-space-md {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.login-body {
  padding: 1rem 0;
}
.login-button-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.login-options-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.login-option-card {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.4rem 1rem 0.4rem 0.4rem;
  border-radius: 0.4rem;
  background-color: #f7f7f7;
  cursor: pointer;
}
.login-icon-space {
  width: 1.5rem;
  height: 1.5rem;
}

.Header-logo > img {
  width: 6rem;
  object-fit: contain;
}

/* layout */
.Login-container {
  width: 100%;
  min-height: 100vh;
  background-color: #2dd4bf;
  display: grid;
  place-content: center;
  position: relative;
  box-shadow: var(--shadow);
}
.login-area-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
}

.login-area-wrapper {
  width: 100%;
  max-width: 30rem;
  z-index: 100;
  margin: 2rem;
}

.Bg-img {
  max-width: 45rem;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-action {
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .Bg-img {
    display: none;
  }

  .Login-container {
    width: 100%;
    min-height: unset;
    background-color: unset;
    display: unset;
    place-content: unset;
    position: unset;
    box-shadow: unset;
  }
  .login-area-wrapper {
    width: unset;
    max-width: unset;
    z-index: 100;
    margin: 0rem;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
    max-width: unset;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 1rem;
  }
  .mobile-header {
    width: 100%;
    padding: 1.2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--shadow);
  }
  .login-action {
    margin-top: 3rem;
  }
}
