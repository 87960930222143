.bg-primary {
  background-color: var(--primary) !important;
}
.bg-secondary {
  background-color: var(--secondary) !important;
}

.primary {
  color: var(--primary) !important;
}
.secondary {
  color: var(--secondary) !important;
}

.bg-light {
  background-color: var(--light) !important;
}
.bg-dark {
  background-color: var(--dark) !important;
}

.mi-light {
  color: var(--light) !important;
}
.mi-dark {
  color: var(--dark) !important;
}

.bg-grey-500 {
  background-color: var(--grey-500) !important;
}
.bg-grey-400 {
  background-color: var(--grey-400) !important;
}
.bg-grey-300 {
  background-color: var(--grey-300) !important;
}
.bg-grey-200 {
  background-color: var(--grey-200) !important;
}

.grey-500 {
  color: var(--grey-500) !important;
}
.grey-400 {
  color: var(--grey-400) !important;
}
.grey-300 {
  color: var(--grey-300) !important;
}

.grey-200 {
  color: var(--grey-200) !important;
}
