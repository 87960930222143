.Benefits_container {
  padding: 4rem 0;
}

.Benefits_card_one_wrapper {
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  background-color: var(--light);
  border-radius: 1rem;
}

.Benefits_card_two_wrapper {
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 2rem;
  background-color: var(--light);
  border-radius: 1rem;
}
.Benefits_one_content_area {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Benefits_two_content_area {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.check_icon_wrapper {
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  background-color: greenyellow;
  display: grid;
  place-content: center;
}

.check_icon_wrapper > i {
  font-size: 1.25rem;
  color: #111;
}

.Benefits_solo_tile {
  display: grid;
  grid-template-columns: 3rem 1fr;
  gap: 1rem;
}

.Benefits_content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.Benefits_card_wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.Benefits_one_image_area {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Benefits_one_image_area > img {
  max-width: 20rem;
  object-fit: contain;
}

.mega_vector_postion {
  position: relative;
}

.mega_vector_illustration > img {
  position: absolute;
  width: 50rem;
  object-fit: contain;
  bottom: -8rem;
  left: -10%;
}

.Benefits_content > p {
  text-align: justify;
}

@media screen and (max-width: 786px) {
  .Benefits_card_one_wrapper {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    background-color: var(--light);
    border-radius: 1rem;
  }

  .Benefits_card_two_wrapper {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    background-color: var(--light);
    border-radius: 1rem;
  }
  .Benefits_container {
    padding: 3rem 0;
  }
  .mega_vector_illustration {
    display: none;
  }
}

@media screen and (max-width: 586px) {
  .Benefits_card_one_wrapper {
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    background-color: var(--light);
    border-radius: 1rem;
  }

  .Benefits_card_two_wrapper {
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    background-color: var(--light);
    border-radius: 1rem;
  }
  .Benefits_solo_tile {
    display: grid;
    grid-template-columns: 2rem 1fr;
    gap: 1rem;
  }
  .check_icon_wrapper {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background-color: greenyellow;
    display: grid;
    place-content: center;
  }

  .check_icon_wrapper > i {
    font-size: 1rem;
    color: #111;
  }
  .Benefits_container {
    padding: 2.5rem 0;
  }
}
