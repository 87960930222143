.dashboard-grid-layout {
  margin: 1.5rem 0;
  padding: 1rem;
  border-radius: 0.6rem;
  background-color: #fff;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  gap: 2rem;
}
.side-links {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #f7f7f7;
  border-radius: 0.3rem;
  cursor: pointer;
  border: 1px solid #eee;
}

.side-links.active {
  background-color: #2dd4bf;
  color: #fff !important;
}

.sidebar-body {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.Sidebar-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.sidebar-heading {
  margin-bottom: 0.6rem;
}

.sidebar-tail {
  margin-top: 2rem;
}

.page-colors {
  background-color: #fff !important;
}

.Dashboard_Container {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.Tab_mainContainer {
  padding: 0.6rem 0;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  border-bottom: 1px solid #ddd;
  width: 100%;
  overflow-x: scroll;
}

.Tab_cards {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 0.6rem;
  padding: 0.6rem 1.6rem 0.6rem 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
  color: #707070;
}
.Tab_cards.active {
  background-color: #2dd4bf;
  color: #fff;
}
