@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
html {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}
body {
  background-color: #f0f0f0 !important;
}
.mi-container {
  width: 100%;
  max-width: 85rem;
  display: block;
  margin: 0 auto;
  padding: 0 1rem;
}

.section-space {
  padding: 3rem 0;
}

.mi-link {
  cursor: pointer;
}

.page-color {
  background-color: #f7f7f7;
}

.mi-title-label {
  display: inline-flex;
  padding: 0.8rem 2rem;
  background-color: #f7f7f7;
  color: var(--dark);
  border-radius: 2rem;
}
.mi-title-label > h1 {
  text-transform: uppercase;
}
.mi-title-label.variant-1 {
  background-color: #ffd978;
}
.mi-title-label.variant-2 {
  background-color: #cfb8ff;
}
.mi-title-label.variant-3 {
  background-color: #67d6fa;
}
.mi-title-label.variant-4 {
  background-color: #c9fa75;
}
.Vector-position {
  position: relative;
}
.vector-image {
  width: 16rem;
  object-fit: contain;
}

.vector-illustration {
  position: absolute;
  display: block;
}

.right-postion {
  left: -9rem;
  top: 50%;
  transform: translateY(-30%);
}

.faq-left-postion {
  left: -10rem;
  bottom: -8rem;
}

.faq-right-postion {
  top: -5rem;
  right: -8rem;
}

.price-left-postion {
  top: -8rem;
  left: -11rem;
}

.price-right-postion {
  right: -10rem;
  bottom: -8rem;
}
@media screen and (max-width: 786px) {
  .vector-illustration {
    display: none;
  }
}
