.wrapper-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.wrapper-header > i {
  font-size: 1.3rem;
}

.access-key {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.8rem 1.2rem;
  background: #192738;
  border-radius: 5px;
}

.key-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
}

.key-reset {
  background-color: #4f46e5;
  padding: 0.6rem 1rem;
  color: #fff;
  border-radius: 0.4rem;
  cursor: pointer;
}

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: #ebeff4;
  padding: 1.25rem;
  border-radius: 0.6rem;
}
.dashboard-wrapper-alt {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.25rem;
  border-radius: 0.6rem;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.info-division {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.status-key {
  padding: 0.4rem 0.8rem;
  font-weight: 500;
  border-radius: 0.4rem;
  color: #fff;
}

.fail-bg {
  background-color: #e5465b;
}

.success-bg {
  background-color: #05bc1d;
}

.fail {
  color: #e5465b;
}

.success {
  color: #05bc1d;
}

.personal-card {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dashboard-details-grid-three-column {
  padding: 1.5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(20rem, 100%), 1fr));
  gap: 3rem;
}

.plan-card {
  background-color: #fff;
  border-radius: 0.6rem;
  cursor: pointer;
}

.plan-banner {
  position: relative;
  width: 100%;
  height: 6rem;
  background-color: rgb(244, 183, 103);
  border-radius: 0.6rem 0.6rem 0 0;
}
.plan-body {
  padding: 2.5rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.info-ico {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 1.8rem;
  background-color: #ebeff4;
  display: grid;
  place-content: center;
}

.info-ico > i {
  font-size: 1rem;
  color: #4f46e5;
}

.plan-type > p {
  position: absolute;
  left: 1.5rem;
  bottom: -1rem;
  padding: 1rem;
  border-radius: 0.4rem;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: #192738;
  color: #fff;
}
