.Showcase_container {
  background-color: var(--dark);
  padding: 3rem 0;
}

.Showcase_two_column_grid {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.Showcase_content_area {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Showcase_content {
  display: flex;
  max-width: 37rem;
  flex-direction: column;
  gap: 1.5rem;
}

.Showcase_action_btn {
  padding: 1.5rem 0;
}
.Showcase_image_area {
  display: grid;
  place-content: center;
}
.Showcase_image_area > img {
  width: 100%;
  max-width: 35rem;
}

@media screen and (max-width: 786px) {
  .Showcase_two_column_grid {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .Showcase_image_area {
    display: none;
  }
  .Showcase_content_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 2rem;
  }
  .Showcase_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 586px) {
  .Showcase_content_area {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .Showcase_content {
    display: flex;
    max-width: 32rem;
    flex-direction: column;
    gap: 1rem;
  }

  .Showcase_action_btn {
    padding: 1rem 0;
  }
}

.text-justify {
  text-align: justify;
}
