.price-card {
  display: inline-flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.6rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
}

.price-header {
  display: flex;
  align-items: baseline;
  gap: 0.6rem;
}

.price-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(16rem, 100%), 1fr));
  gap: 2rem;
}

.addon-card {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.check-ico {
  background-color: #d4cfff;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  display: grid;
  place-content: center;
}
.check-ico > i {
  font-size: 1rem;
  color: #5243c2;
}

.pack-addon-list {
  padding: 1.2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.price-middle {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 1rem 0;
}

.pack-button {
  padding: 1rem 0;
}

.Price-list-container {
  padding: 5rem 0;
}

.price-card.active {
  transform: scale(110%);
  background-color: rgb(227, 247, 254);
}

.price_table_vector {
  position: relative;
}

.price-list-inner-container {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
