.docs-index {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.docs-grid-layout {
  margin: 1.5rem 0;
  padding: 1rem;
  border-radius: 0.6rem;
  background-color: #fff;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1.25rem;
}

.doc-card-header {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.8rem 1rem;
  background-color: #2dd4bf;
  border-bottom: 1px solid #eee;
  border-radius: 0.4rem 0.4rem 0 0;
}
.doc-card {
  border: 1px solid #eee;
  border-radius: 0.4rem;
}

.docs-link {
  color: rgb(62, 80, 96);
  padding: 0.8rem 2.2rem;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.docs-link:last-child {
  border-bottom: none !important;
}
.docs-link.active {
  color: rgb(0, 114, 229);
  background-color: rgb(240, 247, 255);
}

.docs-link:hover {
  color: rgb(62, 80, 96);
  background-color: rgb(250, 250, 250);
}

.doc-content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.Content-base-card {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #ebeff4;
  border-radius: 0.6rem;
}

.text-highlighted {
  background: rgba(40, 63, 89, 0.15);
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  margin: 0 0.45rem 0 0.3rem;
}

pre {
  background: #192738;
  padding: 1rem;
  overflow: hidden;
  overflow-x: scroll;
  width: 100%;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 13px;
  position: relative;
  max-width: none;
  font-family: Menlo, Bitstream Vera Sans Mono, DejaVu Sans Mono, Monaco,
    Consolas, monospace;
  border: 0 !important;
}

pre a {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.25);
  padding: 9px 9px;
  position: absolute;
  right: 10px;
  font-family: "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial,
    sans-serif;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .docs-grid-layout {
    padding: 2rem 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }
}
