/* Constant Fonts */
.mi-heading {
  font-size: 1.8rem;
}
.mi-heading-lite {
  font-size: 1.45rem;
}
.mi-subheading {
  font-size: 1.25rem;
}
.mi-subheading-lite {
  font-size: 1.1rem;
}
.mi-body {
  font-size: 1rem;
}
.mi-note {
  font-size: 0.875rem;
}
.mi-small {
  font-size: 0.75rem;
}

/* Responsive Fonts */
.mi-title {
  font-size: 2.85rem;
  line-height: 3.5rem;
}
.mi-subtitle {
  font-size: 1.5rem;
}
/* Font Weights */
.f-100 {
  font-weight: 100;
}
.f-200 {
  font-weight: 200;
}

.f-300 {
  font-weight: 300;
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

.f-600 {
  font-weight: 600;
}

.f-700 {
  font-weight: 700;
}
.f-900 {
  font-weight: 900;
}

/* Font Line Heights */
.mi-line-xl {
  line-height: 3rem;
}
.mi-line-md {
  line-height: 2.5rem;
}
.mi-line-sm {
  line-height: 2rem;
}

.mi-line-xs {
  line-height: 1.8rem;
}

@media screen and (max-width: 568px) {
  .mi-title {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .mi-subtitle {
    font-size: 1rem;
  }
}
