.Footer_footer {
  background-color: var(--dark);
}

.newsletter_input_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  align-items: center;
  gap: 0.4rem;
  /* background-color: var(--light); */
  border-radius: 0.2rem;
  padding: 0.45rem;
}
.brand_logo_wrapper_footer > img {
  width: 12rem;
  object-fit: contain;
}
input {
  border: none;
  outline: none;
  padding: 0.6rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  font-family: "Roboto", sans-serif;
}

input[type="submit"] {
  background-color: var(--primary);
  color: #fff;
  border-radius: 0.2rem;
  cursor: pointer;
}

.footer_grid_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(12rem, 100%), 1fr));
  gap: 3rem;
  padding: 5rem 0;
}

.footer_contact_card {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.footer_contact_card > i {
  font-size: 1.25rem;
  color: var(--light);
}
.footer_link {
  color: var(--light);
}
.footer_link:hover {
  color: var(--primary);
}

.footer_brand {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.footer_contact {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer_menu {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.footer_menu_text {
  position: relative;
}
.footer_menu_text::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 2rem;
  height: 2px;
  background-color: var(--primary);
}
.footer_menulist {
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
}

.footer_social_media {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.footer_social_media > i {
  color: yellowgreen;
  font-size: 2rem;
}

.Footer_copyrights {
  padding: 2rem 0;
  border-top: 1px solid #333;
}
