.mi-btn {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  outline: none;
  border-radius: 0.3rem;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 14px 0px;
  font-weight: 500 !important;
  cursor: pointer;
}
.mi-btn-lg {
  font-size: 1rem;
  padding: 1.25rem 2.5rem;
}
.mi-btn-xl {
  font-size: 1rem;
  padding: 0.85rem 2.2rem;
}
.mi-btn-md {
  font-size: 0.95rem;
  padding: 0.75rem 2.15rem;
}
.mi-btn-sm {
  font-size: 0.875rem;
  padding: 0.45rem 0.9rem;
}

.btn-full {
  width: 100%;
  text-align: center;
  justify-content: center;
}
/* Basic Button Class */
.mi-btn-prime {
  background-color: var(--btn-primary);
  border: 1px solid var(--btn-primary);
  color: var(--light) !important;
}
.mi-btn-prime:hover {
  background-color: var(--btn-primary-hover);
  border: 1px solid var(--btn-primary-hover);
  color: var(--light) !important;
}

.mi-btn-second {
  background-color: var(--light);
  border: 1px solid var(--btn-primary);
  color: var(--btn-primary) !important;
}

.mi-btn-second:hover {
  background-color: var(--btn-primary);
  border: 1px solid var(--btn-primary);
  color: var(--light) !important;
}

.mi-btn-white {
  background-color: transparent;
  border: 1px solid var(--light);
  color: var(--light) !important;
}
.mi-btn-link {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--light) !important;
}
.mi-btn-link:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--btn-primary) !important;
}

.mi-btn-white:hover {
  background-color: var(--btn-primary);
  border: 1px solid var(--btn-primary);
  color: var(--light) !important;
}

/* Basic Other button Class */
.mi-btn-disable {
  cursor: default;
  box-shadow: none;
  background-color: var(--grey-200);
  border: 1px solid var(--grey-200);
  color: var(--grey-400) !important;
}

@media screen and (max-width: 586px) {
  .mi-btn-xl {
    font-size: 1rem;
    padding: 0.75rem 2.1rem;
  }
  .mi-btn-md {
    font-size: 0.95rem;
    padding: 0.6rem 2rem;
  }
  .mi-btn-sm {
    font-size: 0.85rem;
    padding: 0.4rem 0.85rem;
  }
}
