:root {
  --primary: #1673fe;
  --secondary: #1e293b;
  --btn-primary: #4f46e5;
  --btn-primary-hover: #4338ca;
  --dark: #0b0b0b;
  --light: #f8fafc;
  --grey-500: #64748b;
  --grey-400: #94a3b8;
  --grey-300: #cbd5e1;
  --grey-200: #e5e7eb;
  --shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
