:root {
  --header-height: 5rem;
}

.Header_container {
  width: 100%;
  background-color: var(--dark);
  height: var(--header-height);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-bottom: 1px solid #2c2c2c;
}

.Header_nav {
  width: 100%;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.brand_logo_wrapper > img {
  width: 8rem;
  object-fit: contain;
}

.Header_navlist {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.Header_navlink > p {
  letter-spacing: 0.025rem;
  color: var(--light);
  transition: all 0.3s ease-in-out;
}
.Header_navlink > p:hover {
  color: var(--btn-primary) !important;
}
.Header_button_wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.Header_navRight {
  display: flex;
  align-items: center;
  gap: 4.5rem;
}

.mobile_menu_icon {
  display: none;
}

@media screen and (max-width: 786px) {
  .mobile_menu_icon {
    display: block;
  }
  .Header_navRight {
    display: none;
  }
}
