.Features_container {
  padding: 6rem 0;
  background-color: var(--dark);
}
.Features_inner_wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

/* FeatureCard */
.FeatureCard_header_grid {
  display: grid;
  grid-template-columns: 1.5fr 0.6fr;
  align-items: flex-end;
  gap: 1rem;
}

.points_card {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.points_card > i {
  color: greenyellow;
}

.FeatureCard_header_points {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.FeatureCard_header_title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.FeatureCard_content {
  max-width: 36rem;
}

.FeatureCard_container {
  padding: 4rem;
  border-radius: 1rem;
  background-color: #272727;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.FeatureCard_body_tile_card {
  display: grid;
  grid-template-columns: 4rem 1fr;
  gap: 2rem;
}

.tile_card_image > img {
  width: 4rem;
  object-fit: contain;
}

.FeatureCard_body_grid {
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  gap: 2rem;
}
.FeatureCard_body_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.FeatureCard_body_image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.FeatureCard_body_image > img {
  max-width: 20rem;
}

/* How its work Card */
.WorkingCard_container {
  padding: 3rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.WorkingCard_title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
}
.WorkingCard_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(16rem, 100%), 1fr));
  gap: 2rem;
}
.WorkingCard_tile_icon {
  width: 4rem;
  height: 4rem;
  border-radius: 0.6rem;
  display: grid;
  place-content: center;
}
.WorkingCard_tile_icon.v1 {
  background-color: greenyellow;
}
.WorkingCard_tile_icon.v2 {
  background-color: #ffd978;
}
.WorkingCard_tile_icon.v3 {
  background-color: #fa7a68;
}
.WorkingCard_tile_icon.v4 {
  background-color: #cfb8ff;
}
.WorkingCard_tile {
  padding: 3rem;
  background-color: #272727;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 786px) {
  .FeatureCard_header_grid {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 1rem;
  }
  .FeatureCard_container {
    padding: 3rem;
    border-radius: 1rem;
    background-color: #272727;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  .FeatureCard_body_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

@media screen and (max-width: 586px) {
  .FeatureCard_header_grid {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 1rem;
  }
  .FeatureCard_container {
    padding: 2rem;
    border-radius: 1rem;
    background-color: #272727;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .FeatureCard_body_tile_card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
