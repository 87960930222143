.About_image {
  width: 4rem;
  object-fit: contain;
}
.About_container {
  background-color: var(--dark);
}

.About_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.6rem;
}
.About_mainCard {
  padding: 0 4rem 4rem 4rem;
  position: relative;
}
.About_card_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
  padding: 4rem;
  border-radius: 1rem;
  background-color: #191919;
}

.About_container {
  padding: 3rem 0;
}

@media screen and (max-width: 886px) {
  .About_mainCard {
    padding: 0 2rem 2rem 2rem;
    position: relative;
  }
  .About_card_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    padding: 2.5rem;
    border-radius: 1rem;
    background-color: #191919;
  }

  .About_container {
    padding: 2.5rem 0;
  }
}

@media screen and (max-width: 586px) {
  .About_mainCard {
    padding: 0 1.5rem 1.5rem 1.5rem;
    position: relative;
  }
  .About_card_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    padding: 2rem;
    border-radius: 1rem;
    background-color: #191919;
  }

  .About_container {
    padding: 1.5rem 0;
  }
}
